/**
 * = Custom forms
 */

 .custom-control-label {
    // Background-color and (when enabled) gradient
    &:before {
        box-shadow: $custom-control-box-shadow;
        @if $enable-transitions {
            transition: $input-transition;
        } 
    }

    span {
        position: relative;
        top: 2px;
    }
} 

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Custom File Input

.custom-file-label {
    background-color: #f3f3f5;
  
    &::after {

      background-color: #f3f3f5;
    }
}


//Select
.custom-select{
    font-size: $input-btn-font-size;
    box-shadow:none;

    &.custom-select-shadow {
        box-shadow: $input-box-shadow;
        transition: box-shadow .15s ease;
    
        &:focus {
            box-shadow: $shadow-input-focus;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

